import React, { useState, useCallback, useEffect, useRef } from 'react';
import './App.css';

const rates = {
  "DKK": 100,
  "EUR": 744.40,
  "PRL": 1240.67,
}

const DKK = 100;
const EUR = 744.40;
const PRL = 1240.67;

const sanitize = (value) => {
  const fixedForDecimal = value.toString().replace(",", ".");
  return fixedForDecimal;
}

const convert = (value, from, to) => {
  return ((value * from) / to).toFixed(2);
}

function App() {
  const [pearls, setPearls] = useState(0)
  const [dkk, setDkk] = useState(0)
  const [eur, setEur] = useState(0)
  const prlField = useRef(null);
  const eurField = useRef(null);
  const dkkField = useRef(null);

  const updateValues = useCallback((value, currency) => {
    if (currency != PRL) {
      setPearls(convert(value, currency, PRL));
    }
    if (currency != EUR) {
      setEur(convert(value, currency, EUR));
    }
    if (currency != DKK) {
      setDkk(convert(value, currency, DKK))
    }
  }, [setDkk, setEur, setPearls]);

  const onPearlInputChanged = useCallback((e) => {
    const newValue = sanitize(e.target.value);
    setPearls(newValue)
    updateValues(newValue, PRL);
  }, [setPearls, updateValues]);

  const onDkkInputChanged = useCallback((e) => {
    const newValue = sanitize(e.target.value);
    setDkk(newValue);
    updateValues(newValue, DKK);
  }, [setDkk, updateValues]);

  const onEurInputChanged = useCallback((e) => {
    const newValue = sanitize(e.target.value);
    setEur(newValue);
    updateValues(newValue, EUR);
  }, [setEur, updateValues]);

  const onClickClearPrl = useCallback(() => {
    setPearls('')
    updateValues(0, PRL);
    prlField.current.focus();
  },[setPearls, updateValues, prlField]);

  const onClickClearEur = useCallback(() => {
    setEur('')
    updateValues(0, EUR);
    eurField.current.focus();
  },[setEur, updateValues, eurField]);

  const onClickClearDkk = useCallback(() => {
    setDkk('')
    updateValues(0, DKK);
    dkkField.current.focus();
  },[setDkk, updateValues, dkkField]);

  return (
    <div className="App">
      <div className="currency">
        <label for="prl">PRL </label>
        <input
          id="prl"
          inputMode="decimal"
          value={pearls}
          onChange={onPearlInputChanged} 
          ref={prlField}
          />
        <button onClick={onClickClearPrl}>X</button>
      </div>
      <div className="currency">
        <label for="dkk">DKK </label>
        <input
          id="dkk"
          inputMode="decimal"
          value={dkk}
          onChange={onDkkInputChanged} 
          ref={dkkField}
          />
        <button onClick={onClickClearDkk}>X</button>
      </div>
      <div className="currency">
        <label for="eur">EUR </label>
        <input
          id="eur"
          inputMode="decimal"
          value={eur}
          onChange={onEurInputChanged} 
          ref={eurField}
          />
        <button onClick={onClickClearEur}>X</button>
      </div>
    </div>
  );
}

export default App;
